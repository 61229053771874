<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
            @datachange="getList"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="종합검진병원 목록"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrowMst" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveMst"
            @btnCallback="saveCallback"/>
          <!-- <c-btn label="LBLSEARCH" icon="search"  @btnClicked="getList"/> -->
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hospital-manage',
  data() {
    return {
      grid: {
        columns: [
          // {
          //   required: true,
          //   name: 'plantCd',
          //   field: 'plantCd',
          //   label: 'LBLPLANT',
          //   align: 'left',
          //   style: 'width:120px',
          //   type: 'plant',
          //   sortable: false,
          // },
          {
            required: true,
            name: 'hospitalName',
            field: 'hospitalName',
            label: '병원명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'hospitalLocation',
            field: 'hospitalLocation',
            label: 'LBLLOCATION',
            style: 'width:300px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      isSave: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.hospital.list.url;
      this.saveUrl = transactionConfig.hea.hospital.save.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
    addrowMst() {
      this.grid.data.push({
        hospitalId: uid(),
        hospitalName: '',
        hospitalLocation: '',
        useFlag: 'Y',
        sortOrder: this.grid.data.length + 1,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    saveMst() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeRowMst() {
     
    },
  }
};
</script>
